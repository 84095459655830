@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Jomolhari&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jomolhari&family=Julius+Sans+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Encode+Sans+SC:wght@100..900&family=Jomolhari&family=Julius+Sans+One&display=swap");

html {
  scroll-behavior: smooth;
}

/* Custom styles for react-slick */
.slick-dots li button:before {
  color: #fff !important;
  opacity: 0.25;
}

.slick-dots li.slick-active button:before {
  opacity: 1;
  color: #fff !important;
}

.slick-prev:before,
.slick-next:before {
  color: #fff !important;
  font-size: 24px !important;
}

.slick-prev {
  left: -30px !important;
}

.slick-next {
  right: -30px !important;
}
